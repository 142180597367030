@import 'variables-start';

//Loader START

#main-loader {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
    & > .loader-spin {
	    border: 16px solid $main-primary-color;
	    border-radius: 50%;
	    border-top: 16px solid #fff;
	    width: 120px;
	    height: 120px;
	    -webkit-animation: spin 1s linear infinite; /* Safari */
	    animation: main-spin 1s linear infinite;
    }

	/* Safari */
	@-webkit-keyframes main-spin {
	    0% { -webkit-transform: rotate(0deg); }
	    100% { -webkit-transform: rotate(360deg); }
	}

	@keyframes main-spin {
	    0% { transform: rotate(0deg); }
	    100% { transform: rotate(360deg); }
	}
}

//Loader END